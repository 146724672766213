#blood_pressure_root * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#blood_pressure_root div,
#blood_pressure_root p,
#blood_pressure_root ul>li,
#blood_pressure_root ol>li { color: #585858;  margin-left: 0px;}
#blood_pressure_root p { margin-bottom: 15px; }
#blood_pressure_root p.text { margin: 0px; flex: 1; }

#blood_pressure_root a,
#blood_pressure_root a:hover,
#blood_pressure_root a:visited { color: #0099ff }
#blood_pressure_root a:active { color: #e20886; }

#blood_pressure_root button.btn-primary {
  cursor: pointer;
  background: #e20886 url(components/form/chevron-right.png) no-repeat;
  background-position: right 14px top 14px;

  color: white;
  font-weight: bold;
  line-height: 1em;
  font-size: 0.9em;
  height: 40px;
  width: 240px;
  border: none;
  border-radius: 5px;
  outline: none !important;
}

#blood_pressure_root button.btn-primary:disabled,
#blood_pressure_root button.btn-primary.disabled {
  background: #e07db7;
}

#blood_pressure_root p button.link,
#blood_pressure_root button.link, 
#blood_pressure_root a.link {
  border: none;
  display: inline !important;
  align-items: unset;
  cursor: pointer;
  background: none;
  color: #0099ff;
  outline: none !important;
  line-height: 1em;
  font-size: 1em;
  padding: 3px 0px;
  margin: 0px;
  text-decoration: underline;
  text-decoration-color: #0099ff;
  text-align: left;
}

#blood_pressure_root button.link:hover {
  color: #585858;
  text-decoration: none;
}

#blood_pressure_root ul {
  list-style: none; /* Remove default bullets */
  margin-left: 0;
  list-style-position: outside;
  display: table;

  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

#blood_pressure_root ul li {
  font-size: 1em;
  line-height: 1.5em;
  display: table-row;
}
#blood_pressure_root ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #e20886; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: table-cell; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
}
