  .checkbox button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    width: 27px;
    height: 27px;
    margin: 0;
    padding: 8px;
    font-size: 20px;
    text-align: center;
    -webkit-appearance: none;
    border: none;
    background: url('./check-sprite.png') no-repeat -0px -24px;
    vertical-align: middle;
  }
  .checkbox button.fa-check {
    background: url('./check-sprite.png') no-repeat -0px -0px;
  }
  .checkbox span {
    margin-left: 10px;
    cursor: pointer;
    font-size: 0.9em;
  }
  